<template>
  <Box
    component="label"
    px="6"
    py="1.5"
    height="36px"
    flex
    alignItems="center"
    spaceX="2"
    :class="noHover ? '' : 'cursor-pointer hover:bg-gray-50'"
  >
    <slot />
  </Box>
</template>

<script setup lang="ts">
import { Box } from '@lasso/luikit'

defineProps<{
  noHover?: boolean
}>()
</script>
